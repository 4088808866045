<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h1 class="text-white">
            Budget Impact Model
          </h1>
          <h2 class="text-white">
            Link Generator
          </h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h3 class="text-white">LIVE LINK ACTIVATION LIST</h3>
        </div>
      </div>
      <div
        v-for="(link, index) in links" :key="index"
        class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-1 mb pb-5">
          <span class="dummy-input">{{ link.customer }}</span>
        </div>
        <div class="cell small-12 medium-3 mb">
          <button
            v-if="link.active === 1"
            @click="toggleLink(index)"
            class="button blue-active expanded">ACTIVE</button>
          <button
            v-else
            @click="toggleLink(index)"
            class="button blue-inactive expanded">INACTIVE</button>
        </div>
        <div class="cell small-12 medium-2 mb pt-2">
          <span @click="deleteLink(index)" class="text-white link">
            <img
              width="30px"
              style="position:relative;top:-4px;"
              src="../../assets/logos/bin.svg" alt="" />
          </span>
        </div>
        <!--
        <div v-if="link.active === 1" class="cell small-12 medium-8 medium-offset-1 mb pb-5">
          <div class="link-wrap">
            <input
              class="link-holder"
              v-on:focus="$event.target.select()"
              ref="clone"
              readonly
              :value="link.uri"/>
            <button @click="copy(index)" class="link-copy">COPY LINK</button>
          </div>
        </div>
        -->
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-7 medium-offset-1 mb pt-20">
          <p class="text-white text-small">
            Biogen-192046 Date of preparation: February 2023
          </p>
        </div>
        <div class="cell small-12 medium-3 mb pt-20">
          <p class="text-right">
            <router-link class="image-circle" to="/link-list">
              <img width="40px" src="../../assets/logos/contacts.svg" alt="" />
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../../assets/logos/biogen_logo.svg" alt="" />
        <img class="bottom-logo" src="../../assets/logos/Byooviz_logo.png" alt="" />
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'Links',
  components: {
    appMessage: Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      links: [],
      message: '',
      showMessage: false,
      viewportHeight: 0,
    };
  },
  methods: {
    copy(index) {
      this.$refs.clone[index].focus();
      document.execCommand('copy');
    },
    deleteLink(index) {
      const link = this.links[index];
      axios.get(`/links/delete/${link.id}.json?token=${this.token}`)
        .then((response) => {
          this.links = response.data.links;
        })
        .catch(() => {
          this.message = 'Sorry, something went wrong.';
          this.showMessage = true;
        });
    },
    toggleLink(index) {
      const link = this.links[index];
      axios.get(`/links/toggleLink/${link.id}.json?token=${this.token}`)
        .then((response) => {
          this.links = response.data.links;
        })
        .catch(() => {
          this.message = 'Sorry, something went wrong.';
          this.showMessage = true;
        });
    },
    getLinks() {
      axios.get(`/links/getLinks.json?token=${this.token}&user=${this.userid}`)
        .then((response) => {
          this.links = response.data.links;
        })
        .catch(() => {
          this.message = 'Sorry, something went wrong.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.viewportHeight = window.innerHeight;
    this.getLinks();
  },
};
</script>
